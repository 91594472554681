import React from 'react';
import classNames from 'classnames';

import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';
import { Label } from 'shared/components/elements/elements.components';
import { EM_DASH } from 'app/globals/constants';

import './data-label-pair.component.scss';

interface Props {
  className?: string;
  label: ResourceKey | (() => JSX.Element);
  labelSuffix?: string;
  data: string | number | (() => JSX.Element | JSX.Element[]) | JSX.Element;
  hideIfEmpty?: boolean;
  isSensitive?: boolean;
  [key: string]: any;
}

export const DataLabelPair: React.FunctionComponent<Props> = ({className, label, labelSuffix, data, hideIfEmpty, isSensitive, ...props}) => {
  const actualValue = typeof data === 'function' ? data() : data;

  if (hideIfEmpty && !actualValue) {
    return null;
  }

  return (
    <div className={classNames('data-label-pair', className)} {...props}>
      {label != null && typeof label !== 'function'
        ? <Label resource={label}>{labelSuffix || ''}</Label>
        : null
      }
      {typeof label === 'function'
        ? <Label>{label()}{labelSuffix || ''}</Label>
        : null
      }
      <ErrorBoundary errorType="DATA_LEVEL_ERROR">
        <div className={`data ${isSensitive ? 'fs-mask' : ''}`}>{(actualValue === '' || actualValue == null) ? EM_DASH : actualValue}</div>
        {props.children}
      </ErrorBoundary>
    </div>
  );
};
